"use client";
import { formatDate } from "@utils/format";
import { removeBaseUrl } from "@utils/url";
import isAfter from "date-fns/isAfter";
import isSameDay from "date-fns/isSameDay";
import isSameMonth from "date-fns/isSameMonth";
import Link from "next/link";
import React, { useMemo, useState } from "react";
import ReactCalendar from "react-calendar";

type Props = {
  url?: string;
  events: (PloneSearchResult & {
    start: string;
    end: string;
    location: string;
  })[];
};

function Calendar(props: Props) {
  const { events, url } = props;
  const [currentDate, setCurrentDate] = useState(new Date());

  const currentEvents = useMemo(() => {
    return events.filter((event) =>
      isSameMonth(new Date(event.start), currentDate),
    );
  }, [events, currentDate]);

  const currentDayEvents = useMemo(() => {
    return events.filter((event) =>
      isSameDay(new Date(event.start), currentDate),
    );
  }, [events, currentDate]);

  const nextEvent = events.find((event) =>
    isAfter(new Date(event.start), new Date()),
  );

  return (
    <div className="calendar">
      <h2 className="calendar__title">Veranstaltungen</h2>
      <ReactCalendar
        className="calendar__element"
        tileClassName={({ date, view }) => {
          return view === "month" &&
            currentEvents.some((event) =>
              isSameDay(date, new Date(event.start)),
            )
            ? "has-events"
            : null;
        }}
        onActiveStartDateChange={({ activeStartDate }) => {
          if (!activeStartDate) return;
          setCurrentDate(activeStartDate);
        }}
        onClickDay={(activeStartDate) => {
          if (!activeStartDate) return;
          setCurrentDate(activeStartDate);
        }}
        nextAriaLabel="Nächster Monat"
        prevAriaLabel="Vorheriger Monat"
        next2AriaLabel="Nächstes Jahr"
        prev2AriaLabel="Vorheriges Jahr"
      />
      <div className="calendar__events">
        {!currentDayEvents.length ? (
          <p className="calendar__no-events">
            Am ausgewählten Tag sind keine Veranstaltungen geplant.
          </p>
        ) : null}
        {currentDayEvents.length
          ? currentDayEvents.map((event) => (
            <Link
              key={event["@id"]}
              href={removeBaseUrl(event["@id"])}
              className="calendar__event"
            >
              <div className="calendar__event-date">
                <time dateTime={event.start.split("T")[0]}>
                  {formatDate(event.start)}
                </time>
                , {event.location}
              </div>
              <p className="calendar__event-title">{event.title}</p>
            </Link>
          ))
          : null}
        {url ? (
          <Link href={removeBaseUrl(url)} className="button calendar__button">
            Zur Übersicht
          </Link>
        ) : null}
      </div>
    </div>
  );
}

export default Calendar;
